define("fakenews/templates/components/icons/shares-with-popup", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/components/icons/shares-with-popup.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "ui-popup", [], ["tagName", "i", "class", "share square icon", "content", ["subexpr", "t", ["labels.shares"], [], ["loc", [null, [1, 57], [1, 76]]], 0, 0], "position", "top center"], ["loc", [null, [1, 0], [1, 100]]], 0, 0], ["inline", "euro-number-format", [["get", "shares", ["loc", [null, [2, 21], [2, 27]]], 0, 0, 0, 0], false], [], ["loc", [null, [2, 0], [2, 35]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});