define('fakenews/locales/en/translations', ['exports'], function (exports) {
  exports['default'] = {
    about: {
      q1: {
        answer: '<p>Ainda bem que perguntaste! Existem vários recursos que te podem ajudar identificar notícias falsas, como por exemplo o curso gratuito online <a href="https://www.cncs.gov.pt/pt/curso-cidadao-ciberinformado/" target="_blank">Cidadão Ciberinformado</a>. Para além disso: </p><p><strong>Lê antes de partilhar!</strong> Se vires algo duvidoso, verifica os factos! Por vezes basta fazer uma pesquisa rápida no Google. Caso contrário, recomendo o recurso a <a href="https://combatefakenews.lusa.pt/verificadores-em-portugal/" target="_blank">páginas de Fact Checking</a>, já que estas verificam conteúdo duvidoso frequentemente.</p><p><strong>Pensa bem nas fontes a que recorres.</strong> Se utilizares o browser Chrome, experimenta o plug-in <a href="https://chrome.google.com/webstore/detail/fake-news-monitor/abchpppcldemnoihgbnodlbdbnibcjan?hl=en">Fake News Monitor</a>, que te alerta quando visitas determinados websites associados à divulgação de notícias duvidosas. Também enumera as tendências políticas dos websites.</p><p><strong>Pensa bem se alguém está a beneficiar da manipulação das tuas emoções.</strong> Como acontece no jogo, as pessoas podem gerar visualizações e lucro com a promoção do medo, da raiva e de outras emoções fortes que geram partilhas online. Se considerares que um artigo é perturbador, isto é um mais um motivo para o leres com atenção  e verificares os factos.</p>',
        question: "Como posso informar-me melhor sobre notícias falsas e outras formas de desinformação?"
      },
      q2: {
        answer: '<p>Embora alguns conceitos tenham sido simplificados para melhorar a jogabilidade, a ideia geral tem por base acontecimentos da vida real. Mais concretamente, um grupo de adolescentes da Macedónia que lucraram com notícias falsas durante as eleições de 2016 nos Estados Unidos. Para saber mais sobre o fenómeno da distribuição de notícias falsas com fins lucrativos que inspirou este jogo, recomendo os seguintes artigos (em inglês):</p><ul><li><a href=" http://www.npr.org/2016/12/14/505547295/fake-news-expert-on-how-false-stories-spread-and-why-people-believe-them" target="_blank">Fake News Expert On How False Stories Spread And Why People Believe Them</a></li><li><a href="https://www.buzzfeed.com/craigsilverman/how-macedonia-became-a-global-hub-for-pro-trump-misinfo?utm_term=.kuvlVDdZV5#.auv1eworeD" target="_blank">How Teens In The Balkans Are Duping Trump Supporters With Fake News</a></li><li><a href="http://www.npr.org/sections/alltechconsidered/2016/11/23/503146770/npr-finds-the-head-of-a-covert-fake-news-operation-in-the-suburbs" target="_blank">We Tracked Down A Fake-News Creator In The Suburbs. Here\'s What We Learned</a></li><li><a href="https://www.theguardian.com/media/2017/feb/06/liberal-fake-news-shift-trump-standing-rock" target="_blank">Fake news for liberals: misinformation starts to lean left under Trump</a></li><li><a href="http://www.pbs.org/newshour/updates/real-consequences-fake-news-stories-brain-cant-ignore/" target="_blank">The very real consequences of fake news stories and why your brain can’t ignore them</a></li></ul>',
        question: "Que eventos da vida real inspiraram este jogo?"
      },
      q3: {
        answer: '<p>Olá!</p><p>Sou Amanda Warner, a criadora e programadora do Fake It To Make It. Podes ler mais sobre mim e o meu trabalho em <a href="http://www.amanda-warner.com" target="_blank">http://www.amanda-warner.com</a>. Podes enviar sugestões para feedback@fakeittomakeitgame.com</p><p>Robbie Dooley criou a música de Fake It To Make It. Pode saber mais sobre o Robbie em <a href="http://www.robbiedooley.com">http://www.robbiedooley.com</a></p>',
        question: "Quem criou este jogo?"
      },
      q4: {
        answer: "<p>Sim! O jogo está disponível publicamente para apresentação ou utilização dentro ou fora da sala de aula.</p><p>Agradeço sempre que me informem sobre a forma como estão a utilizar o jogo e gosto de receber todo o feedback. Pode enviar-me uma mensagem de correio eletrónico para feedback@fakeittomakeitgame.com. Se é professor e utiliza este jogo nas suas aulas, estaria particularmente interessado em receber os seus comentários.</p>",
        question: "Posso utilizar este jogo na minha aula/apresentação/casamento?"
      },
      q5: {
        answer: "<p><strong>Resposta curta:</strong> Espero que não.</p><p><strong>Resposta mais longa:</strong> O processo de criação de notícias falsas já está bem documentado online. Se alguém quiser criar um website de notícias falsas, já tem acesso à informação de que precisa. No entanto, reconheço que há uma diferença entre informação e inspiração. É possível que este jogo possa inspirar alguém a criar notícias falsas, mas estou disposta a correr esse risco. Penso que o potencial de mudança positiva nas pessoas que o joguem vale a pena.</p><p>A minha esperança é que, ao sensibilizar os jogadores para as formas e os motivos pelos quais são criadas e distribuídas notícias falsas, as pessoas se tornem mais céticas em relação ao que encontrarem no futuro. De um modo geral, penso que vale a pena compreender melhor as formas e os motivos pelos quais somos manipulados por terceiros, seja por lucro ou por poder.</p><p>A imagem abaixo apresenta as ações, atitudes e crenças que eu gostaria de ver nos jogadores.</p>",
        question: "Porque é que criaste este jogo? Estás a ensinar as pessoas a escrever notícias falsas?"
      },
      q6: {
        answer: '<ul><li><a href="http://kotaku.com/fake-news-video-game-is-a-little-too-real-1793660926">Fake News Video Game Is A Little Too Real</a> de Luke Plunkett, Kotaku</li><li><a href="http://www.theverge.com/2017/3/27/15072374/fake-it-to-make-it-browser-game-fake-news">Live a day in the life of a fake news creator</a> de Megan Farokhmanesh, The Verge </li><li><a href="http://www.spiegel.de/netzwelt/games/fake-it-to-make-it-erschreckend-realistisches-browserspiel-a-1141411.html">Lügen, hetzen, Gewinne einfahren</a> de Jörg Breithut, Der Spiegel</li><li><a href="http://www.sueddeutsche.de/digital/browser-spiel-fake-it-to-make-it-fake-news-zum-selberbauen-1.3450453">Fake News zum Selberbauen</a> de Yannic Hannebohn, Suddeutsche Zeitung</li><li><a href="http://ici.radio-canada.ca/nouvelle/1025030/fausses-nouvelles-jeu-video-educatif">Un jeu qui sensibilise aux fausses nouvelles</a> de Maxence Matteau, Radio Canada</li><li><a href="http://www.20minutes.fr/high-tech/2041319-20170331-jeu-fake-it-to-make-it-peut-etre-efficace-sensibiliser-jeunes-fake-news">Le jeu «Fake it to make it» peut-il être efficace pour sensibiliser les jeunes aux fake news?</a> de Delphine Bancaud, 20 Minutes</li><li><a href="http://www.ibtimes.co.uk/fake-it-make-it-scarily-realistic-game-that-simulates-spread-viral-clickbait-fake-news-1614167">Fake It To Make It: The scarily realistic game that simulates spread of viral clickbait fake news</a> de Mary-Ann Russon, International Business Times</li><li><a href="http://wgnradio.com/2017/04/01/saturday-night-special-with-amy-guth-4117-media-literacy/">Media Literacy, Saturday Night Special</a> com Amy Guth no WGN Radio Chicago</li><li><a href="http://www.newstalk.com/podcasts/The_Green_Room/Highlights_from_The_Green_Room/186311/How_does_fake_news_work">How Does Fake News Work, The Green Room</a> com Orla Barry no NewstalkFM</li></ul>',
        question: "Que meios de comunicação social (verídicos) falaram sobre o jogo?"
      }
    },
    copy_articles: {
      copy_message_multiple: "A copiar {{number}} artigos.",
      copy_message_one: "A copiar 1 artigo",
      copying_articles: "A copiar o artigo...",
      header: "Copiar Artigos",
      instructions: "Seleciona os artigos abaixo que pretendes copiar para {{siteName}}.",
      is_incomplete: "Seleciona pelo menos um artigo para copiar",
      is_ready: "Clica aqui para copiar artigos",
      time_required: "Serão necessárias {{timeRequired}} horas para copiar e reformatar estes artigos."
    },
    create_a_site: {
      action_button: "Criar este website",
      domain_and_hosting: "Domínio e alojamento",
      domain_info: "Embora alguns websites de notícias falsas que utilizam alojamento gratuito tenham obtido bons resultados, registar um domínio é uma opção que confere muito mais credibilidade e não é assim tão dispendiosa.",
      domain_registration_for: "Registo de domínio para {{siteName}}",
      generate_random: "Gerar aleatório",
      header: "Criar um website",
      logo: "Logótipo",
      monetization: "Monetização",
      monthly_hosting_fee: "Custo mensal do alojamento",
      name_placeholder: "Introduz um nome para o website...",
      review: {
        instructions: "Rever e submeter",
        missing: {
          credibility: "Seleciona opções que concedam ao teu website um índice de credibilidade mínimo de 30. Poderá ser necessário alterares as tuas escolhas de domínio, alojamento e tema",
          domain_hosting: "Seleciona uma opção de domínio e alojamento",
          logo: "Seleciona um logótipo",
          monetization: "Seleciona uma opção de monetização",
          name: "Introduz um nome para o website",
          overall: "Para continuar, terás de:",
          theme: "Selecionar uma opção para o tema"
        },
        ready_to_submit: 'Revê as informações à direita. Se tudo estiver bem, clica no botão "Criar Website".',
        too_expensive: "A criação deste website requer mais fundos do que dispões atualmente! Volta aos passos anteriores e altera as tuas escolhas para reduzir as despesas associadas ao teu website."
      },
      site_name: "Nome do website",
      summary: {
        due_now: "A pagar agora:",
        monthly_costs: "Custo mensal",
        one_time_cost: "Custo único",
        remaining: "Restante:",
        total: "Total",
        yearly_costs: "Custo anual",
        your_funds: "Os teus fundos:"
      },
      theme: "Tema",
      theme_info: "Utiliza o software gratuito LetterPress para criar o teu website de forma rápida e fácil! Podes investir num tema para dar maior credibilidade ao teu website."
    },
    create_profile: {
      button: {
        is_complete: "Criar perfil",
        is_incomplete: "Preenche todos os campos acima"
      },
      customization_options: {
        high: "Alto - 5 horas",
        low: "Baixo - 1 hora",
        medium: "Médio - 3 horas"
      },
      customizing_profile: "Personalização do perfil",
      header: "Cria um novo perfil (falso) nas redes sociais",
      hours_to_spend: "Horas que serão gastas na personalização deste perfil",
      name_placeholder: "Nome falso (por exemplo, José Cardoso)",
      select_photo: "Seleciona uma fotografia"
    },
    filters: {
      all_political_tags: "Todos os partidos políticos",
      all_sources: "Todas as fontes",
      change_filter_prompt: "Altera ou remove os filtros para expandir a sua pesquisa.",
      filter_by: "Filtrado por:",
      no_matching_bases: "Nenhuma base de artigos corresponde aos teus filtros.",
      no_matching_items: "Nenhum item corresponde aos teus filtros.",
      political_party: "Partido político",
      popular: "Popular",
      recent: "Recente",
      sort_by: "Ordenar por:",
      source: "Fonte",
      topic: "Tópico"
    },
    goals_display: {
      final_goal_complete: "Acho que já dominaste o processo, por isso vou deixar de te dar objetivos intermédios. Continua o bom trabalho!",
      header: "Objetivos",
      hide_completed_goals: "Ocultar objetivos concluídos",
      hint_prompt: "Podes estar a sentir dificuldades com o teu objetivo atual:<ul><li>{{goalText}}</li></ul> Se precisares de uma dica, clica no objetivo na caixa de diálogo Objetivos para receberes mais informações.",
      next_goal_introduction: "O teu próximo objetivo é:",
      next_goal_introduction_2: "Podes iniciar estas instruções novamente, com dicas adicionais, clicando no objetivo na caixa de diálogo <strong>Objetivos</strong>.",
      show_completed_goals: "Mostrar objetivos concluídos"
    },
    help: {
      copyArticles: '<p>Podes copiar artigos existentes de outros meios de comunicação social questionáveis. É uma forma rápida de começar! Ao selecionar artigos, presta atenção aos tópicos principais do artigo, à credibilidade e à polémica.</p><p><i class="ui tags icon"></i><strong>Os tópicos dos artigos</strong> são os temas principais do artigo. Se um tópico tiver uma cor, significa que é direcionado para um determinado partido político. Por exemplo, <span class="ui orange tag label">Conspiração</span> refere-se a uma conspiração que apela aos membros do partido cor de laranja, enquanto as etiquetas <span class="ui purple tag label">Conspiração</span> apelariam aos membros do partido púrpura.</p><p><span class="ui large label">Credibilidade</span> é uma reflexão sobre se um público sensato aceitaria facilmente o artigo como verdadeiro. As pessoas acreditam em muitas coisas diferentes, pelo que não é necessariamente um reflexo da veracidade.</p><p><span class="ui large label">Polémica</span> reflete a probabilidade de as pessoas se interessarem pelo artigo (e de o partilharem).</p>',
      copy_prompt: "Clica aqui para obter informações importantes sobre a cópia de artigos.",
      initial_instructions: '<p>Poderás ganhar dinheiro ao criar websites de notícias e lucrar quando as pessoas visualizam e clicam nos anúncios apresentados no teu website. É muito fácil, desde que consigas gerar tráfego suficiente. </p><p>Os teus websites serão direcionados para as pessoas nos Estados Unidos. Porquê? Bem, porque as visualizações e os cliques de pessoas neste país são pagos com valores mais elevados do que noutros países. Podes não te interessar pela política dos Estados Unidos, mas podes utilizar o drama e as polémicas associados à mesma para lucrar!</p><p>Vamos criar websites de "notícias", mas não nos vamos preocupar muito com a verdade. As notícias falsas demoram menos tempo a criar e, muitas vezes, disseminam-se mais facilmente do que as notícias verdadeiras, uma vez que não estão tão limitadas pelos factos.</p><p>Para te ajudar a começar, vou apresentar-te alguns pequenos objetivos que te irão ajudar neste percurso.</p>',
      plantArticles: '<div class="ui mini steps"><div class="step"><i class="newspaper icon"></i><div class="content"><div class="title">O teu artigo</div></div></div><div class="step"><i class="user icon"></i><div class="content"><div class="title">Perfil de utilizador das Redes Sociais</div></div></div><div class="step"><i class="users icon"></i><div class="content"><div class="title">Grupo das Redes Sociais</div></div></div></div><p>Irás "Partilhar um Artigo" ao partilhares um artigo com o teu perfil de utilizador num grupo específico. Tenta selecionar uma combinação que atraia "gostos" e partilhas suficientes para que o conteúdo comece a disseminar-se nas redes sociais. Se o artigo receber a atenção e visualizações necessárias, começarás a ganhar dinheiro!</p>',
      plant_prompt: "Clica aqui para obter informações importantes sobre a partilha de artigos nas redes sociais.",
      prompt: "Olá!",
      writeArticles: '<p>Escrever artigos implica mais esforço do que apenas copiá-los, contudo dá-te a capacidade de disseminar conteúdo relacionado com os tópicos em voga e de produzir conteúdo direcionado a produzir determinadas emoções.</p><div class="ui mini steps"><div class="step"><i class="lab icon"></i><div class="content"><div class="title">Base de artigo</div></div></div><div class="step"><i class="wizard icon"></i><div class="content"><div class="title">Elementos de suporte do artigo (0 a 4)</div></div></div><div class="step"><i class="announcement icon"></i><div class="content"><div class="title">Título</div></div></div></div><p><strong>A base do artigo</strong> é o alicerce do teu artigo: pensa base do artigo como sendo a tua inspiração! Podes encontrar potenciais bases em acontecimentos da vida real, medos comuns, publicações nas Redes Sociais, etc.</p><p><strong>Elementos de suporte</strong> são técnicas que utilizarás durante a escrita do teu artigo, principalmente para criar credibilidade e polémica. Podes adicionar até quatro elementos de suporte a um artigo e provavelmente será boa ideia experimentar várias opções para conseguires a combinação certa.</p><p>Alguns elementos de suporte adicionam e distorcem os tópicos aos artigos. Por exemplo, o tópico <span class="ui tag label">Veteranos de guerra</span> pode ser distorcido para <span class="ui tag orange label">Tratamento injusto dos veteranos de guerra</span> ou <span class="ui tag purple label">Tratamento injusto dos veteranos de guerra</span> mediante aplicação dos elementos de suporte certos.</p><p>Sabes o que é um <strong>título</strong>, certo? O teu título dar vontade de clicar imediatamente. Recomendamos que utilizes letras maiúsculas e afirmações extremas!</p>>',
      write_prompt: "Clica aqui para obter informações importantes sobre a escrita de artigos."
    },
    home_page: {
      delete_confirm: "Tens a certeza de que pretendes eliminar este jogo? Atenção, não poderás anular esta ação!",
      last_played: "Jogado pela última vez",
      paragraph_1_1: "Bem-vindo ao Fake It To Make It, um jogo de impacto social sobre notícias falsas. Podes ler mais sobre este jogo",
      paragraph_1_2: "nesta página",
      paragraph_1_3: ". No entanto, sugiro que jogues primeiro!",
      paragraph_2: "Se precisares de parar a meio do jogo, não deverá haver problema, desde que tenhas o armazenamento local ativado nas definições do teu browser. (Provavelmente tem.) Basta regressares a este menu principal, onde encontrarás os teus jogos guardados automaticamente.",
      paragraph_3: "Embora este jogo funcione em browsers de telemóvel, provavelmente será mais agradável jogar num dispositivo maior, como um computador ou um tablet, em vez do telemóvel.",
      total_funds: "Total de fundos:",
      your_existing_games: "Os teus jogos atuais:"
    },
    labels: {
      political_slant: "Inclinação política",
      drama: "Polémica",
      request_to_join: "Pedir para aderir",
      neutral: "Neutro",
      purple_party: "Partido Púrpura",
      views: "Visualizações",
      locked: "Bloqueado",
      topic_match: "Correspondência de tópicos",
      lets_go: "Vamos lá!",
      articles: "Artigos",
      base_topics: "Tópicos de base",
      trending_topic: "Tópico em voga",
      rejected: "Rejeitado",
      no: "Não",
      friends: "Amigos",
      yes: "Sim",
      believability: "Credibilidade",
      too_expensive: "Demasiado caro",
      credibility: "Credibilidade",
      shares: "Partilhas",
      political_match: "Correspondência política",
      play: "Jogar",
      try_this: "Experimenta isto!",
      revenue: "Receitas",
      emotions_triggered: "Emoções provocadas",
      group_post_topics: "Tópicos de publicação em grupo",
      debunked: "Desacreditado",
      selected: "Selecionado",
      got_it: "Já está!",
      article_topics: "Tópicos do artigo",
      likes: "Gostos",
      pending_approval: "Aprovação pendente",
      profile: "Perfil",
      'delete': "Eliminar",
      members: "Membros",
      group: "Grupo",
      already_planted: "Artigo já partilhado",
      reason: "Motivo",
      advice: "Conselho",
      article: "Artigo",
      next: "Seguinte",
      orange_party: "Partido Cor de Laranja",
      additional_results_details: "Detalhes adicionais dos resultados",
      'continue': "Continuar",
      your_plant: "A tua publicação",
      trending_topics: "Tópicos em voga"
    },
    loan: {
      loan_repayment: "Reembolso do empréstimo",
      loan_terms: "Uh oh! Ficaste sem dinheiro.<br><br>Posso fazer-te um empréstimo de {{loanAmount}} € para cobrir as suas despesas em dívida, mas terás de me reembolsar em {{repaymentAmount}} € no prazo de um mês. Concordas?"
    },
    menu: {
      all_sites: "Todos os websites",
      back_to_current_game: "Voltar ao jogo atual",
      copy_articles: "Copiar Artigos",
      go_to_about: "Sobre as notícias falsas e este jogo",
      go_to_main_menu: "Ir para o menu principal",
      goal_complete: "Atingiste o teu objetivo! Viva!",
      goal_progress: "Tens {{percentGoalComplete}}% do teu objetivo de {{gameGoalCost}} €",
      monthly_hosting_fee: "Custo mensal do alojamento",
      none_yet: "Nenhum ainda",
      options: "Opções",
      options_information: "O jogo é colocado em pausa e o teu progresso é guardado automaticamente. Para aceder aos jogos guardados no menu principal.",
      options_pause: "Opções / Pausa",
      plant_an_article: "Partilhar um Artigo",
      recent_expenses: "Despesas recentes",
      show_expenses: "Mostrar despesas",
      site_dashboard: "Painel de controlo do website",
      start_a_new_game: "Iniciar um jogo novo",
      upcoming_expenses: "Despesas previstas",
      write_an_article: "Escrever um Artigo",
      you_have: "Tem"
    },
    meta: {
      description: "Cria o teu próprio império de notícias (falsas) com o Fake It To Make It, um jogo de simulação de impacto social sobre a forma como as notícias falsas são criadas e distribuídas para gerar lucro.",
      title: "Fake It To Make It"
    },
    move_article: {
      cancel_move: "Esquece! Deixar o artigo em {{siteName}}",
      header: "Mover Artigo",
      instructions: 'Podes mover o artigo <span class="bold">{{articleTitle}}</span> para outro website. Os "gostos", as partilhas, as visualizações e as receitas apresentadas no artigo serão todos definidos como 0, uma vez que este artigo será associado a um novo endereço Web. Irá manter o dinheiro que já ganhou com <span class="bold">{{siteName}}</span>.',
      select_site_instructions: "Seleciona o website para o qual pretende mover o artigo."
    },
    new_game: {
      get_started: {
        instructions: 'Verifica as tuas seleções à direita. Se estiver tudo bem, clica no botão "Vamos Lá!" para começar!',
        missing: {
          goal: "Seleciona um objetivo (algo que quer comprar)",
          guide: "Seleciona um personagem",
          name: "Introduz o teu nome próprio",
          overall: "Para que possamos começar, é necessário:"
        },
        prompt: "Vamos lá começar!"
      },
      goal_summary: "Vamos começar a ganhar dinheiro para que possas {{goalLongDescription}}!",
      guide_prompt: "Qual é a tua personagem?",
      header: "Criar um jogo novo",
      music_off: "Se preferires ouvir as tuas próprias músicas (ou ficar só em silêncio), podes desligar a música do jogo no botão circular de áudio no lado direito do menu.",
      name_placeholder: "Nome próprio",
      name_prompt: "Qual é o teu nome próprio?",
      name_summary: "Olá, {{userName}}!",
      purchase_hint: "Dica: é claramente mais fácil atingir um objetivo financeiro mais pequeno. Podes sempre tentar comprar o apartamento ou o carro mais tarde!",
      purchase_prompt: "O que é que queres comprar?"
    },
    plant_an_article: {
      action_button: "Partilhar Artigo",
      article: {
        instructions: "Selecionar artigo de {{siteName}}",
        none: "Este website não tem artigos! Para que possas publicar um artigo, tens de copiá-lo. Utiliza o item de menu acima!"
      },
      feedback: {
        interest_level: {
          good: "Bom trabalho! As pessoas parecem interessar-se por este artigo e, por isso, estão a divulgá-lo.",
          hostile: "Bom, não há dúvida de que enfureceste algumas pessoas ao publicar um artigo tendo como alvo o partido político contrário.",
          mild: "Algumas pessoas parecem interessadas neste artigo.",
          moderate: "Parece que este artigo está a suscitar algum interesse.",
          none: "Que seca! Parece que as pessoas deste grupo não se interessaram por este artigo. Tenta outra coisa.",
          viral: "Uau! Este artigo tem um elevado grau de credibilidade, um elevado grau de polémica e foca-se num tópico em voga que está politicamente alinhado com este grupo. Combinação perfeita! Vê como é popular!"
        }
      },
      group: {
        added: "{{profile}} foi adicionado ao grupo {{group}}",
        instructions: "Seleciona o Grupo das Redes Sociais",
        join_groups: "Aderir a Grupos",
        no_profile_selected: "Tens de selecionar um perfil para poder selecionar um grupo.",
        none: 'Este perfil não é membro de nenhum grupo! Utiliza o separador "Aderir a Grupos" acima para encontrar um grupo ao qual poderá aderir. Poderás solicitar a adesão a mais do que um grupo.',
        pending_approval: "{{profile}} pediste para aderir ao grupo {{group}}. Podes pedir para aderir a outros grupos enquanto esperas.",
        rejected: "{{profile}} foste rejeitado pelo grupo {{group}}",
        select_group: "Selecionar dos Grupos de {{profileName}}"
      },
      header: "Partilhar um Artigo",
      profile: {
        buy_profiles: "Comprar perfis",
        create_a_new_profile: "Criar um novo perfil",
        instructions: "Seleciona o Perfil das Redes Sociais",
        none: "Para iniciar a divulgação do teu artigo, tens de criar ou comprar um perfil falso nas Redes Sociais. Utiliza os itens do menu acima.",
        select_profile: "Seleciona entre os teus perfis",
        select_to_buy_for: "Selecionar para comprar por {{amount}} €",
        success: "Perfil das Redes Sociais comprado",
        too_expensive: "Não tem dinheiro para comprar este perfil"
      },
      review: {
        instructions: "Rever e submeter",
        missing: {
          article: "Seleciona um artigo",
          group: "Seleciona um Grupo das Redes Sociais",
          overall: "Para continuar, terás de:",
          profile: "Seleciona um Perfil das Redes Sociais"
        },
        ready_to_submit: 'Pronto para partilhar o teu artigo? Clique no botão "Partilhar o Artigo" à direita! Em alternativa, podes fazer alterar um dos passos acima.'
      }
    },
    share_and_copyright: {
      copyright: '  <p>Criado por Amanda Warner da <a href="https://www.curiousbird.se/" target="_blank">Curious Bird</a>. Mantem o contacto <a href="https://www.curiousbird.se/contact" target="_blank">ao aderir à nossa lista de correio eletrónico.</a></p>',
      game_title: "Fake It To Make It",
      long_description: "Cria o teu próprio império de notícias (falsas) jogando Fake It To Make It, um jogo de simulação de impacto social sobre a forma como as notícias falsas são criadas e distribuídas para gerar lucro.",
      share_request: "Se gostas deste jogo, considera partilhá-lo:",
      short_description: "Cria o teu próprio império de notícias (falsas) com o Fake It To Make It, um jogo de simulação."
    },
    site_dashboard: {
      advice: {
        believability: "Verifica os índices de credibilidade dos artigos neste website. Se as pessoas virem algo no teu website que sabem ser verdade, é mais provável que acreditem no resto.",
        debunked: "Os websites de verificação de factos podem desacreditar os teus artigos mais populares, reduzindo a credibilidade geral do teu website. Felizmente, tornou-se popular questionar a credibilidade dos websites de verificação de factos e, por isso, algumas pessoas não se importam.",
        domain_and_hosting: "Aceda a Definições do website abaixo para ver as opções de alteração do domínio e de alojamento.",
        established: "O teu website irá parecer mais credível e respeitável se tiveres mais artigos.",
        political_congruity: "A sociedade hoje em dia tende a estar politicamente divida e, por isso, o teu website terá geralmente mais sucesso se for orientado para um dos dois partidos políticos.",
        theme: "Acede a Definições do website abaixo para ver as opções de alteração do tema."
      },
      article_believability: "Credibilidade do artigo",
      confirm_delete: "Tens a certeza de que pretende eliminar este artigo? Não poderás anular esta ação!",
      copy_prompt: 'Clica acima no item de menu "Copiar Artigos".',
      copy_write_prompt: "Utiliza os itens do menu acima para copiar ou escrever alguns artigos.",
      debunked_articles: "Artigos desacreditados",
      domain_and_hosting: {
        header: "Domínio e alojamento",
        information: "Se pretenderes utilizar um domínio e uma opção de alojamento diferentes para o teu website, seleciona uma das opções abaixo. Todos os custos serão imediatamente deduzidos da tua conta, por isso escolhe cuidadosamente!"
      },
      header: "Painel de controlo de",
      no_articles: "Este website ainda não tem artigos!",
      political_congruity: "Coerência política",
      site_credibility: "Credibilidade do website",
      site_looks_established: "O website parece fidedigno",
      site_settings: "Definições do website",
      theme: {
        header: "Tema",
        information: "Se pretenderes utilizar um tema diferente para o teu website, seleciona-o abaixo. Todos os custos serão imediatamente deduzidos da sua conta, por isso escolhe cuidadosamente!"
      }
    },
    sites: {
      create_site: "Criar website",
      header: "O Império das Notícias de {{userName}}",
      prompt: "Seleciona um website abaixo"
    },
    special_events: {
      crime_perception: "<p>Uma sondagem recente revela que os níveis de medo do crime são os mais elevados de sempre, embora os índices globais de criminalidade do país sejam metade do que eram há 20 anos. O teu popular artigo <strong>{{articleTitle}}</strong> pode estar a contribuir para isso.</p>",
      debunked: {
        part_one: "<p>O artigo <strong>{{articleTitle}}</strong> foi desacreditado num website de verificação de factos muito conhecido.</p>",
        part_two_first: "<p>Poderás obter algumas visualizações adicionais de pessoas que viram o artigo no website de verificação de factos, mas é pouco provável que o divulguem. Além disso, agora que um artigo deste website foi desacreditado, a credibilidade do website <strong>{{siteName}}</strong> desceu um pouco. Podes consultar mais informações sobre a credibilidade de um website no respetivo painel de controlo.</p>",
        part_two_subsequent: "<p>A credibilidade do website <strong>{{siteName}}</strong> diminuiu.</p>"
      },
      famous_share: {
        a_celebrity: "Uma celebridade",
        orange_party_leader: "Líder da Oposição Manga",
        part_one: "<p>{{person}} acabou de partilhar uma ligação para o teu artigo, <strong>{{articleTitle}}</strong>, nas Redes Sociais. Viva!</p>",
        part_two_first: "<p>Os políticos e as celebridades não são imunes às notícias falsas. Isto é ótimo para ti! Quando as outras pessoas vêem alguém em quem confiam a divulgar notícias falsas, é mais provável que acreditem que são verdadeiras.</p>",
        purple_party_leader: "Primeira-Ministra Beringela"
      },
      hate_crimes: "<p>Os crimes de ódio contra o grupo de pessoas que visou no artigo popular <strong>{{articleTitle}}</strong> aumentaram 8%.</p>",
      health_advice: "<p>O teu artigo <strong>{{articleTitle}}</strong> pareces estar a ganhar adeptos! As pessoas parecem preocupadas com a saúde. Algumas estão a seguir os conselhos questionáveis do teu artigo.</p>",
      person_got_fired: "<p>De acordo com as notícias (verídicas), uma pessoa acabou de ser despedida, alegadamente por ter partilhado informações incorretas do teu artigo <strong>{{articleTitle}}</strong>.</p>"
    },
    winning: {
      can_return_prompt: "(Depois de clicar no botão acima ainda poderás voltar e continuar o jogo, se assim desejares)",
      description: "<p>Bom trabalho! Atingiste o teu objetivo de obter dinheiro suficiente para {{gameGoal}}.</p><p>Não queres cair no tipo de armadilha que acabaste de criar, pois não? Clica no botão abaixo para saber mais sobre as notícias falsas da vida real e o objetivo por detrás deste jogo.</p>",
      header: "Ganhaste!"
    },
    write_an_article: {
      action_button: "Escrever Artigo",
      base_instructions: "Seleciona uma Base de Artigos",
      header: "Escrever um Artigo",
      review: {
        instructions: "Revisão",
        missing: {
          base: "Uma Base de Artigos",
          overall: "Para que possa escrever um artigo, é necessário:",
          title: "O título do artigo"
        },
        ready_to_submit: 'Revê o teu artigo à direita. É suficientemente credível? Tem polémica suficiente? Quando estiveres satisfeito com o artigo, clica no botão "Escrever Artigo" para o escreveres e adicionares ao teu website.'
      },
      submit_title: "Enviar Título",
      summary: {
        article_info: "Este artigo irá demorar {{timeRequired}} horas a ser escrito e terá os seguintes atributos:",
        base: "Base",
        supports: "Elementos de suporte",
        targeted_emotions: "Emoções provocadas",
        topics: "Tópicos",
        your_selections: "As tuas seleções"
      },
      support_instructions: "Seleciona até quatro elementos de suporte de artigos",
      support_tabs_info: "Seleciona um dos separadores abaixo para ver os diferentes tipos de elementos de suporte.",
      title_instructions: "Escrever um título"
    },
    write_article: {
      support_tabs: {
        believability: "Credibilidade",
        general_drama: "Polémica geral",
        orange_party_drama: "Polémica do Partido Cor de Laranja",
        purple_party_drama: "Polémica do Partido Púrpura"
      },
      too_many_bases: "Só podes ter uma base de artigos. Se pretenderes adicionar uma base diferente, remove primeiro a base à direita.",
      too_many_supports: "Já adicionaste o número máximo de elementos de suporte. Se pretenderes adicionar um elemento de suporte diferente, remova primeiro um dos elementos de suporte à direita.",
      writing_article: "Escrever um Artigo"
    }
  };
});